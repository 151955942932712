import AddIcon from '@mui/icons-material/Add';
import {
  Avatar,
  Box,
  Container,
  Fab,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PersonsService from '../../api/services/PersonsService';
import { useLayout } from '../../layout/LayoutContext';
import { fullName, Person } from '../../models/person';

const TableCellHiddenOnPhone = styled(TableCell)(
  sx({
    display: { xs: 'none', sm: 'table-cell' },
  })
);

export default function PersonsPage() {
  const { setTitle } = useLayout();

  const navigate = useNavigate();

  const [rows, setRows] = useState<Person[]>([]);

  useEffect(() => {
    setTitle('Friends');

    PersonsService.fetchAllPersons()
      .then((persons) => setRows(persons))
      .catch(console.error);
  }, []);

  return (
    <Box>
      <Container maxWidth="lg" sx={{ mt: { xs: 0, sm: 2 }, px: { xs: 0, sm: 2 } }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCellHiddenOnPhone>Phone number</TableCellHiddenOnPhone>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '& > :last-child td, & > :last-child th': {
                  border: 0,
                },
              }}>
              {rows.map((person) => (
                <TableRow
                  key={person.id}
                  hover
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate(`/persons/${person.id}`);
                  }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ minWidth: 200, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Avatar alt={fullName(person) ?? ''} src={person.profileImageUrl} sx={{ height: 30, width: 30 }} />
                    {fullName(person)}
                  </TableCell>
                  <TableCellHiddenOnPhone>
                    {person.phones?.find((phone) => phone.expired === undefined || phone.expired === false)?.number ??
                      ''}
                  </TableCellHiddenOnPhone>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Fab
        color="primary"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16,
        }}
        onClick={() => {
          navigate(`/persons/new`);
        }}>
        <AddIcon />
      </Fab>
    </Box>
  );
}
