import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import Grid3x3OutlinedIcon from '@mui/icons-material/Grid3x3Outlined';
import LanguageIcon from '@mui/icons-material/Language';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PersonsService from '../../api/services/PersonsService';
import { useLayout } from '../../layout/LayoutContext';
import { birthday, fullName, Person } from '../../models/person';

interface ContentLineProps {
  content: string;
  label?: string;
  icon?: JSX.Element;
}

function ContentLine({ content, label, icon }: ContentLineProps) {
  return (
    <Stack gap={1} direction="row" sx={{ alignItems: 'flex-start' }}>
      <Box
        sx={{
          minWidth: 24,
          height: 24,
          '&> svg': {
            fontSize: '1em',
            marginTop: '0.28em',
            width: 20,
          },
        }}>
        {icon}
      </Box>
      <Box
        sx={{
          '&> span': {
            fontSize: '0.9em',
            overflowWrap: 'anywhere',
          },
        }}>
        <Typography component="span">{content}</Typography>
        {label && (
          <Typography
            component="span"
            sx={{
              fontSize: '0.8em',
              fontWeight: '400',
              lineHeight: '0.9em',
              color: '#5f6368',
              letterSpacing: '0.025em',
            }}>
            {` • ${label}`}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}

ContentLine.defaultProps = {
  label: undefined,
  icon: undefined,
};

export default function PersonPage() {
  const params = useParams();
  const navigate = useNavigate();

  const { setTitle } = useLayout();

  const [person, setPerson] = useState<Person | null>(null);

  useEffect(() => {
    if (params.id) {
      PersonsService.fetchPersonById(params.id)
        .then((personData) => setPerson(personData ?? null))
        .catch(console.error);
    }
  }, []);

  useEffect(() => {
    setTitle(fullName(person) ?? '');
  }, [person]);

  return (
    <Box>
      {person && (
        <Container>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ pt: 2, pb: 2 }}>
            <Avatar
              sx={{ bgcolor: deepOrange[500], width: 50, height: 50 }}
              alt={fullName(person) ?? ''}
              src={person.profileImageUrl}
            />
            <Typography component="h1" variant="h5" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              {fullName(person)}
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(`/persons/${params.id}/edit`);
              }}>
              Edit
            </Button>
          </Stack>
          <Divider light />
          <Grid container spacing={2} sx={{ pt: 2, pb: 2 }}>
            <Grid item xs={12} md={6}>
              <Card variant="outlined" sx={{ borderRadius: 3 }}>
                <CardHeader title="Basic Info" />
                <CardContent>
                  <Stack gap={1}>
                    <ContentLine content={fullName(person, false) ?? ''} icon={<PersonOutlinedIcon />} />
                    {person.name.preferred && <ContentLine content={person.name.preferred} label="alternative name" />}
                    {person.name.nick && <ContentLine content={person.name.nick} label="nick" />}
                    {person.gender && <ContentLine content={person.gender} />}
                    {birthday(person).length > 0 && (
                      <ContentLine content={birthday(person)} icon={<CakeOutlinedIcon />} />
                    )}
                    {person.govId && <ContentLine content={person.govId} icon={<Grid3x3OutlinedIcon />} />}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card variant="outlined" sx={{ borderRadius: 3 }}>
                <CardHeader title="Contact details" />
                <CardContent>
                  <Stack gap={1}>
                    {person.phones &&
                      person.phones.map((phone, index) => (
                        <ContentLine
                          content={phone.number}
                          label={phone.label}
                          icon={index === 0 ? <PhoneIcon /> : <> </>}
                        />
                      ))}
                    {person.addresses &&
                      person.addresses.map((address, index) => (
                        <ContentLine
                          content={address.text}
                          label={address.label}
                          icon={index === 0 ? <PlaceIcon /> : <> </>}
                        />
                      ))}
                    {person.websiteAccounts &&
                      person.websiteAccounts.map((account, index) => (
                        <ContentLine
                          content={account.accountId ?? ''}
                          label={account.website ?? account.url}
                          icon={index === 0 ? <LanguageIcon /> : <> </>}
                        />
                      ))}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            {person.relationships && (
              <Grid item xs={12} md={6}>
                <Card variant="outlined" sx={{ borderRadius: 3 }}>
                  <CardHeader title="Relationships" />
                  <CardContent>
                    <Stack gap={1}>
                      {person.relationships.map((relationship, index) => (
                        <ContentLine
                          content={(relationship.person, '')}
                          label={relationship.label}
                          icon={index === 0 ? <PeopleOutlineIcon /> : <> </>}
                        />
                      ))}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Container>
      )}
    </Box>
  );
}
