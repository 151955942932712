import { getAuth, signOut, User } from 'firebase/auth';
import { createContext, useContext, useMemo, useState } from 'react';
import { Location, Navigate, useLocation, useNavigate } from 'react-router-dom';

interface AuthContextType {
  user?: User;

  signin: (user: User, callback?: VoidFunction) => void;
  signout: (callback?: VoidFunction) => void;

  from?: Location;
  setFrom: (location: Location) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = getAuth();
  const [user, setUser] = useState<User | undefined>(auth.currentUser ?? undefined);
  const navigate = useNavigate();

  const [from, setFrom] = useState<Location | undefined>();

  const signin = (newUser: User, callback?: VoidFunction) => {
    setUser(newUser);
    if (callback) {
      callback();
    }
  };

  const signout = async (callback?: VoidFunction) => {
    try {
      await signOut(getAuth());
      setUser(undefined);
      if (callback) {
        callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const location = useLocation();

  auth.onAuthStateChanged((newUser) => {
    if (newUser && !user) {
      signin(newUser, () => {
        if (from) {
          navigate(from, { replace: true });
        } else if (location.pathname === '/auth/login') {
          navigate('/persons', { replace: true });
        }
      });
    } else if (!newUser && user) {
      signout();
    }
  });

  const value = useMemo(() => ({ user, from, setFrom, signin, signout }), [user, from, setFrom, signin, signout]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

export function RequireAuth({ children, redirectTo }: { children: JSX.Element; redirectTo: string }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    auth.setFrom(location);
    return <Navigate to={redirectTo} replace />;
  }

  return children;
}
