import { initializeApp } from 'firebase/app';
import { collection, CollectionReference, DocumentData, getFirestore } from 'firebase/firestore';
import { Note } from '../models/note';
import { Person, PersonLabel } from '../models/person';
import timestampConverter from './timestampConverter';

const firebaseConfig = {
  apiKey: 'AIzaSyBiRQPJVlH7EI2pQBrIvLrA3f_udOw888o',
  authDomain: 'cary-space-2852e.firebaseapp.com',
  databaseURL: 'https://cary-space-2852e-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'cary-space-2852e',
  storageBucket: 'cary-space-2852e.appspot.com',
  messagingSenderId: '448759077935',
  appId: '1:448759077935:web:df4bbc346d65ab2d4cfada',
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const dataPoint = <T = DocumentData>(collectionPath: string) =>
  collection(firestore, collectionPath) as CollectionReference<T>;

export const personsCollection = dataPoint<Person>('persons').withConverter(timestampConverter<Person>());
export const personLabelsCollection = dataPoint<PersonLabel>('person-labels');
export const notesCollection = dataPoint<Note>('notes').withConverter(timestampConverter<Note>());
