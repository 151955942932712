export interface Phone {
  number: string;
  label?: string;
  expired?: boolean;
}

export interface Address {
  text: string;
  label?: string;
  expired?: boolean;
}

export interface Relationship {
  label?: string; // wife, son, father, uncle, etc.
  personId?: string;
  person?: Person;
}

export interface WebsiteAccount {
  website: string;
  accountId?: string;
  url?: string;
}

export interface JobPosition {
  companyId?: string;
  companyName?: string;

  startDate?: Date;
  endDate?: Date;

  currentJob?: boolean;

  title?: string;
  description?: string;
  city?: string;
}

export interface PersonLabel {
  id?: string;
  name: string;
}

export interface Person {
  id?: string;
  name: {
    first?: string;
    last?: string;
    nick?: string;
    preferred?: string;
  };
  profileImageUrl?: string;
  gender?: string;
  birthday: {
    day?: number;
    month?: number;
    year?: number;
  };
  phones?: Phone[];
  addresses?: Address[];

  relationships?: Relationship[];
  websiteAccounts?: WebsiteAccount[];

  positions?: JobPosition[];

  govId?: string; // ID number in China; social account id in US. etc.

  notes: string;

  labels: PersonLabel[];
}

export const PERSON_GENDER_OPTIONS = ['Male', 'Female'];

export function fullName(p: Person | undefined | null, includeAll = true): string | undefined {
  if (!p || !p.name) {
    return '';
  }

  const hasChineseChar = [p.name.last, p.name.first]
    .filter((x) => x !== undefined && x.length > 0)
    .join('')
    .match(/\p{Script=Han}/u);

  let full = '';
  if (hasChineseChar) {
    full = [p.name.last, p.name.first].filter((x) => x !== undefined && x.length > 0).join('');
  } else {
    full = [p.name.first, p.name.last].filter((x) => x !== undefined && x.length > 0).join(' ');
  }

  if (includeAll) {
    full = [full, p.name.preferred, p.name.nick].filter((x) => x !== undefined && x.length > 0).join(' ');
  }
  return full;
}

export function birthday(p: Person | undefined | null) {
  if (!p || !p.birthday) {
    return '';
  }

  let text = 'YY-mm-dd';
  if (p.birthday.year) {
    text = text.replace('YY', String(p.birthday.year));
  }
  if (p.birthday.month) {
    text = text.replace('mm', String(p.birthday.month));
  }
  if (p.birthday.day) {
    text = text.replace('dd', String(p.birthday.day));
  }

  return text === 'YY-mm-dd ' ? '' : text;
}
