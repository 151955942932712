import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme } from '@mui/material/styles';

export const customizeTheme = (prefersDarkMode: boolean) => {
  return createTheme({
    typography: {
      fontFamily: [
        'DIN-Regular',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Hiragino Sans GB',
        'Microsoft YaHei UI',
        'Microsoft YaHei',
        'Source Han Sans CN',
        'sans-serif',
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
      ].join(','),
      fontSize: 12,
    },
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      ...(prefersDarkMode
        ? {
            background: {
              default: '#303030',
              paper: '#424242',
            },
          }
        : {}),
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
    },
  });
};
