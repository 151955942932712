import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Button, Container, IconButton, TextareaAutosize, useTheme } from '@mui/material';
import { format } from 'date-fns';
import MuiMarkdown from 'mui-markdown';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import NotesServices from '../../api/services/NotesServices';
import { useLayout } from '../../layout/LayoutContext';
import { Note } from '../../models/note';

interface NoteWithStatus extends Note {
  editing?: boolean;
}

export default function NotesPage() {
  const { setTitle } = useLayout();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [notes, setNotes] = useState<NoteWithStatus[]>([]);

  useEffect(() => {
    setTitle('Note');

    NotesServices.fetchAllNotes()
      .then((n) => setNotes(n))
      .catch(console.error);
  }, []);

  const newNoteTextareaRef = useRef<HTMLTextAreaElement>(null);
  const onSubmitNewNote = async () => {
    if (newNoteTextareaRef.current === null || newNoteTextareaRef.current.value.length === 0) {
      return;
    }

    try {
      const newNote = await NotesServices.addNewNote({
        time: new Date(),
        content: newNoteTextareaRef.current.value,
        tags: [],
      });
      setNotes([newNote, ...notes]);
      if (newNoteTextareaRef.current) {
        newNoteTextareaRef.current.value = '';
      }
      enqueueSnackbar('Add Suceessfully!', { variant: 'success' });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Save Error! Try Later', { variant: 'error' });
    }
  };

  const onUpdateNote = async (id: string) => {
    const note = notes.find((n) => n.id === id);
    if (note) {
      try {
        await NotesServices.updateNoteById(id, note);
        setNotes(notes.map((n) => (n.id === note.id ? { ...n, editing: false } : n)));

        enqueueSnackbar('Update Suceessfully!', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar('Update Error! Try Later', { variant: 'error' });
      }
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        '&> div': {
          my: 1.5,
          borderRadius: 1,
          p: 1.5,
          pt: 1,
          backgroundColor: 'background.paper',
          '&:hover': {
            boxShadow: theme.shadows[4],
          },
          '&:hover .edit-button': {
            display: 'block',
          },
          '& textarea': {
            backgroundColor: 'transparent',
            color: 'text.primary',
            resize: 'none',
            p: 1,
            outlineStyle: 'none',
          },
        },
      }}>
      <Box>
        <TextareaAutosize
          style={{
            width: '100%',
            border: 0,
            padding: 0,
          }}
          minRows={3}
          placeholder="record everything..."
          autoFocus
          ref={newNoteTextareaRef}
        />
        <Box
          sx={{
            fontSize: '0.8rem',
            color: 'text.secondary',
            lineHeight: '1.5rem',
            display: 'flex',
            flexDirection: 'row-reverse',
          }}>
          <Button variant="contained" size="small" onClick={onSubmitNewNote}>
            Submit
          </Button>
        </Box>
      </Box>
      {notes.map((note) => (
        <Box key={note.id} sx={{ position: 'relative' }}>
          <Box
            sx={{
              fontSize: '0.8rem',
              color: 'text.secondary',
              lineHeight: '1.5rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {format(note.time, 'yyyy-MM-dd HH:mm:ss')}
          </Box>
          {note.editing ? (
            <>
              <TextareaAutosize
                style={{
                  width: '100%',
                  border: 0,
                  padding: 0,
                }}
                autoFocus
                defaultValue={note.content}
                onChange={(event) => {
                  const newContent = event.target.value;
                  setNotes(notes.map((n) => (n.id === note.id ? { ...n, content: newContent } : n)));
                }}
              />
              <Box
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  lineHeight: '1.5rem',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    if (note.id) {
                      onUpdateNote(note.id);
                    }
                  }}>
                  Update
                </Button>
              </Box>
            </>
          ) : (
            <>
              <MuiMarkdown>{note.content}</MuiMarkdown>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 2,
                  right: 2,
                  display: {
                    xs: 'block',
                    sm: 'none',
                  },
                }}
                className="edit-button"
                onClick={() => {
                  setNotes(notes.map((n) => (n.id === note.id ? { ...n, editing: true } : n)));
                }}>
                <ModeEditOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        </Box>
      ))}
    </Container>
  );
}
