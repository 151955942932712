import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthProvider, RequireAuth } from './api/auth';
import AdminLayout from './layout/AdminLayout';
import { LayoutProvider } from './layout/LayoutContext';
import { LoginPage } from './pages/login';
import { NotesPage } from './pages/notes';
import { AddEditPersonPage, PersonPage, PersonsPage } from './pages/persons';
import { ImportPersonsPage } from './pages/tools';
import { customizeTheme } from './theme';

export interface PageProps {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const appTheme = useMemo(() => customizeTheme(prefersDarkMode), [prefersDarkMode]);

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <BrowserRouter>
        <SnackbarProvider maxSnack={3}>
          <AuthProvider>
            <LayoutProvider>
              <Routes>
                <Route path="auth/login" element={<LoginPage />} />
                <Route
                  path="tools/import-persons"
                  element={
                    <RequireAuth redirectTo="/auth/login">
                      <ImportPersonsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/"
                  element={
                    <RequireAuth redirectTo="/auth/login">
                      <AdminLayout />
                    </RequireAuth>
                  }>
                  <Route path="/" element={<Navigate replace to="/persons" />} />
                  <Route path="persons" element={<PersonsPage />} />
                  <Route path="persons/:id" element={<PersonPage />} />
                  <Route path="persons/:id/edit" element={<AddEditPersonPage />} />
                  <Route path="persons/new" element={<AddEditPersonPage />} />
                  <Route path="notes" element={<NotesPage />} />
                </Route>
              </Routes>
            </LayoutProvider>
          </AuthProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
