import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

interface LayoutContextType {
  title: string;
  setTitle: (title: string) => void;
}

const LayoutContext = createContext<LayoutContextType>({
  title: '',
  setTitle: (title) => {
    console.log(`Title Change ${title}`);
  },
});

export function LayoutProvider({ children }: { children: ReactNode }) {
  const [title, setTitle] = useState('');

  useEffect(() => {
    document.title = title.length === 0 ? 'Cary Space' : `${title} - Cary Space`;
  }, [title]);

  const value = useMemo(() => ({ title, setTitle }), [title, setTitle]);
  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
}

export function useLayout() {
  return useContext(LayoutContext);
}
