import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { Avatar, Box, Button, CircularProgress, Container, Divider, IconButton, Stack, Toolbar } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-calculate';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { Autocomplete, TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import PersonsService from '../../api/services/PersonsService';
import { useLayout } from '../../layout/LayoutContext';
import { fullName, Person, PERSON_GENDER_OPTIONS } from '../../models/person';

export default function AddEditPersonPage() {
  const pageParams = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [person, setPerson] = useState<Person | undefined>();
  const [loading, setLoading] = useState(true);

  const { setTitle } = useLayout();

  useEffect(() => {
    if (pageParams.id) {
      PersonsService.fetchPersonById(pageParams.id)
        .then((p) => {
          setPerson(p);
          setLoading(false);
        })
        .catch(console.error);
    } else {
      setTitle('New Friend');
      setLoading(false);
    }
  }, []);

  const saveData = async (data: Person) => {
    if (pageParams.id) {
      try {
        await PersonsService.updatePersonById(pageParams.id, data);
        enqueueSnackbar('Update Suceessfully!', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar('Update Error! Try Later', { variant: 'error' });
      }
    } else {
      try {
        const personDocRef = await PersonsService.addNewPerson(data);
        enqueueSnackbar('Add Suceessfully!', { variant: 'success' });
        navigate(`/persons/${personDocRef.id}`);
      } catch (err) {
        enqueueSnackbar('Save Error! Try Later', { variant: 'error' });
      }
    }
  };

  const formPersonNameDecorator = useMemo(
    () =>
      createDecorator<Person>({
        field: /name\.*/,
        updates: (value, name, allValues) => {
          if (pageParams.id) {
            setTitle(fullName(allValues) ?? '');
          }
          return {};
        },
      }),
    []
  );

  const [uploadPercentage, setUploadPercentage] = useState<number | undefined>();
  const uploadProfileImage = (event: ChangeEvent<HTMLInputElement>, callback: (imageUrl: string) => void) => {
    if (event.target.files && event.target.files.length > 0) {
      const image = event.target.files[0];
      const storageRef = ref(getStorage(), `persons-images/${uuidv4()}-${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      setUploadPercentage(0);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadPercentage(progress);
        },
        (error) => {
          console.log(error);
          // Handle unsuccessful uploads
          enqueueSnackbar('Image upload failed!', { variant: 'error' });
          setUploadPercentage(undefined);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);
            callback(downloadURL);
          });
          setUploadPercentage(undefined);
        }
      );
    }
  };

  // Add & Remove item from array type of property
  // type KeyValue = {
  //   [K in keyof Person]-?: Person[K] extends Array<infer T> | undefined
  //     ? [key: K, value: T]
  //     : never;
  // }[keyof Person];

  // const addItemTo = (...[key, value]: KeyValue) => {
  //   if (person) {
  //     const newArray = [...(person[key] ?? []), value];

  //     setPerson({
  //       ...person,
  //       [key]: newArray,
  //     });
  //   }
  // };

  // const removeItemFrom = (key: KeyValue[0], index: number) => {
  //   if (person && person[key]) {
  //     const oldArray = person[key] as Array<KeyValue[1]>;
  //     const newArray = oldArray.filter((_, i: number) => index !== i);
  //     setPerson({
  //       ...person,
  //       [key]: newArray,
  //     });
  //   }
  // };

  return loading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 200,
      }}>
      <CircularProgress />
    </Box>
  ) : (
    <Form
      onSubmit={async (data: Person) => {
        console.log(data);
        await saveData(data);
      }}
      mutators={{
        ...arrayMutators,
      }}
      decorators={[formPersonNameDecorator]}
      initialValues={person}
      // validate={validate}
      render={({ handleSubmit, submitting, values, form }) => (
        <Container maxWidth="lg">
          <form onSubmit={handleSubmit}>
            <Box sx={{ mt: 2, mb: 9 }}>
              <label htmlFor="file-upload-input">
                <input
                  accept="image/*"
                  id="file-upload-input"
                  multiple={false}
                  type="file"
                  onChange={(event) => {
                    uploadProfileImage(event, (imageUrl) => {
                      form.change('profileImageUrl', imageUrl);
                    });
                  }}
                  style={{ display: 'none' }}
                  disabled={uploadPercentage !== undefined}
                />
                <IconButton
                  color="primary"
                  component="span"
                  sx={{ position: 'relative' }}
                  disabled={uploadPercentage !== undefined}>
                  <Avatar
                    sx={{ width: 150, height: 150, fontSize: 50 }}
                    alt={fullName(values) ?? ''}
                    src={values.profileImageUrl}
                  />
                  {uploadPercentage !== undefined ? (
                    <CircularProgress
                      variant={!uploadPercentage || uploadPercentage <= 5 ? 'indeterminate' : 'determinate'}
                      value={uploadPercentage}
                      sx={{ position: 'absolute' }}
                    />
                  ) : (
                    <EditOutlinedIcon sx={{ position: 'absolute', width: 50, height: 50 }} />
                  )}
                </IconButton>
              </label>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box sx={{ maxWidth: 700, pl: 6 }}>
                <TextField
                  name="name.last"
                  label="Last Name"
                  sx={{
                    mt: 1,
                    '&::before': {
                      content: '""',
                      mask: 'url("/svg/person_outline_black_24dp.svg") no-repeat 50% 50%',
                      maskSize: 'cover',
                      position: 'absolute',
                      left: -48,
                      bottom: '0.28em',
                      width: 24,
                      height: 24,
                      backgroundColor: 'text.secondary',
                    },
                  }}
                />
                <TextField name="name.first" label="First Name" sx={{ mt: 1 }} />
                <TextField name="name.preferred" label="Alternative Name" sx={{ mt: 1 }} />
                <TextField name="name.nick" label="Nickname" sx={{ mt: 1 }} />
                <Autocomplete label="Gender" name="gender" sx={{ mt: 1 }} options={PERSON_GENDER_OPTIONS} />
                <TextField name="govId" label="ID number" sx={{ mt: 1 }} />

                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    mt: 1,
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      mask: 'url("/svg/cake_black_24dp.svg") no-repeat 50% 50%',
                      maskSize: 'cover',
                      position: 'absolute',
                      left: -48,
                      bottom: '0.28em',
                      width: 24,
                      height: 24,
                      backgroundColor: 'text.secondary',
                    },
                  }}>
                  <TextField name="birthday.year" label="Birth Year" sx={{ mt: 0 }} />
                  <TextField name="birthday.month" label="Birth Month" />
                  <TextField name="birthday.day" label="Birth Day" />
                </Stack>
                <FieldArray name="phones">
                  {({ fields }) => (
                    <Box
                      sx={{
                        '& > :first-child': {
                          position: 'relative',
                        },
                        '& > :first-child::before': {
                          content: '""',
                          mask: 'url("/svg/phone_black_24dp.svg") no-repeat 50% 50%',
                          maskSize: 'cover',
                          position: 'absolute',
                          left: -48,
                          bottom: '0.28em',
                          width: 24,
                          height: 24,
                          backgroundColor: 'text.secondary',
                        },
                      }}>
                      {fields.map((item, index) => (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="end"
                          sx={{ mt: 1, gap: 2 }}
                          key={item}>
                          <TextField
                            name={`${item}.number`}
                            label="Phone"
                            sx={{
                              flexGrow: 1,
                            }}
                          />
                          <TextField name={`${item}.label`} label="Label" sx={{ width: 150 }} />
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                              fields.remove(index);
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      ))}
                      <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{ mt: 2 }}
                        onClick={() => {
                          fields.push({ number: '' });
                        }}>
                        Add new phone
                      </Button>
                    </Box>
                  )}
                </FieldArray>
                <FieldArray name="addresses">
                  {({ fields }) => (
                    <Box
                      sx={{
                        '& > :first-child': {
                          position: 'relative',
                        },
                        '& > :first-child::before': {
                          content: '""',
                          mask: 'url("/svg/place_black_24dp.svg") no-repeat 50% 50%',
                          maskSize: 'cover',
                          position: 'absolute',
                          left: -48,
                          bottom: '0.28em',
                          width: 24,
                          height: 24,
                          backgroundColor: 'text.secondary',
                        },
                      }}>
                      {fields.map((item, index) => (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="end"
                          sx={{ mt: 1, gap: 2 }}
                          key={item}>
                          <TextField
                            name={`${item}.text`}
                            label="Address"
                            sx={{
                              flexGrow: 1,
                            }}
                          />
                          <TextField name={`${item}.label`} label="Label" sx={{ width: 150 }} />
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                              fields.remove(index);
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      ))}
                      <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{ mt: 2 }}
                        onClick={() => {
                          fields.push({ text: '' });
                        }}>
                        Add new address
                      </Button>
                    </Box>
                  )}
                </FieldArray>
                <FieldArray name="websiteAccounts">
                  {({ fields }) => (
                    <Box
                      sx={{
                        '& > :first-child': {
                          position: 'relative',
                        },
                        '& > :first-child::before': {
                          content: '""',
                          mask: 'url("/svg/language_black_24dp.svg") no-repeat 50% 50%',
                          maskSize: 'cover',
                          position: 'absolute',
                          left: -48,
                          bottom: '0.28em',
                          width: 24,
                          height: 24,
                          backgroundColor: 'text.secondary',
                        },
                      }}>
                      {fields.map((item, index) => (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="end"
                          sx={{ mt: 1, gap: 2 }}
                          key={item}>
                          <TextField name={`${item}.website`} label="Website" sx={{ width: 150 }} />

                          <TextField
                            name={`${item}.accountId`}
                            label="Account"
                            sx={{
                              flexGrow: 1,
                            }}
                          />
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                              fields.remove(index);
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      ))}
                      <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{ mt: 2 }}
                        onClick={() => {
                          fields.push({});
                        }}>
                        Add new website account
                      </Button>
                    </Box>
                  )}
                </FieldArray>
                <FieldArray name="relationships">
                  {({ fields }) => (
                    <Box
                      sx={{
                        '& > :first-child': {
                          position: 'relative',
                        },
                        '& > :first-child::before': {
                          content: '""',
                          mask: 'url("/svg/diversity_1_black_24dp.svg") no-repeat 50% 50%',
                          maskSize: 'cover',
                          position: 'absolute',
                          left: -48,
                          bottom: '0.28em',
                          width: 24,
                          height: 24,
                          backgroundColor: 'text.secondary',
                        },
                      }}>
                      {fields.map((item, index) => (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="end"
                          sx={{ mt: 1, gap: 2 }}
                          key={item}>
                          <TextField
                            name={`${item}.person`}
                            label="Relationship"
                            sx={{
                              flexGrow: 1,
                            }}
                          />
                          <TextField name={`${item}.label`} label="Label" sx={{ width: 150 }} />
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                              fields.remove(index);
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      ))}
                      <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{ mt: 2 }}
                        onClick={() => {
                          fields.push({});
                        }}>
                        Add new relationship
                      </Button>
                    </Box>
                  )}
                </FieldArray>
              </Box>
            </Box>
            <Toolbar
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                borderTop: '1px solid lightgray',
                borderColor: 'divider',
                width: '100%',
                justifyContent: 'right',
                backgroundColor: 'background.default',
                zIndex: 100,
              }}>
              <LoadingButton
                variant="contained"
                loading={submitting}
                loadingPosition="end"
                endIcon={<SaveIcon />}
                type="submit">
                {pageParams.id ? 'Save' : 'Add'}
              </LoadingButton>
            </Toolbar>
          </form>
        </Container>
      )}
    />
  );
}
