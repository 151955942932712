import GoogleIcon from '@mui/icons-material/Google';
import { Box, Button } from '@mui/material';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../api/auth';

export default function LoginPage() {
  const googleProvider = new GoogleAuthProvider();
  const auth = getAuth();

  const navigate = useNavigate();
  const authState = useAuth();

  const signInWithGoogle = async () => {
    try {
      auth.setPersistence({ type: 'LOCAL' });

      const res = await signInWithPopup(auth, googleProvider);

      authState.signin(res.user, () => {
        const from = '/persons';
        navigate(from, { replace: true });
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Button onClick={signInWithGoogle} variant="contained" color="primary" startIcon={<GoogleIcon />}>
        Login with Google
      </Button>
    </Box>
  );
}
