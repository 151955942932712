import { addDoc, doc, getDocs, orderBy, query, updateDoc } from 'firebase/firestore';
import { Note } from '../../models/note';
import { notesCollection } from '../firestore';

const fetchAllNotes = async () => {
  const q = query(notesCollection, orderBy('time', 'desc'));
  const docsRef = await getDocs(q);
  const docs: Note[] = docsRef.docs.map((noteRef) => {
    return { id: noteRef.id, ...noteRef.data() };
  });

  return docs;
};

const updateNoteById = async (id: string, note: Note) => {
  const noteDocRef = doc(notesCollection, id);
  await updateDoc(noteDocRef, note);
};

const addNewNote = async (note: Note) => {
  const docRef = await addDoc(notesCollection, note);
  return { id: docRef.id, ...note } as Note;
};

export default {
  fetchAllNotes,
  updateNoteById,
  addNewNote,
};
