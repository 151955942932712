import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Timestamp,
  WithFieldValue,
} from 'firebase/firestore';

const timestampConverter = <T extends DocumentData>(): FirestoreDataConverter<T> => ({
  toFirestore(modelObject: WithFieldValue<T>): DocumentData {
    return modelObject;
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): T {
    const ss = snapshot.data();

    const convert = (data: DocumentData) =>
      Object.keys(data).reduce<DocumentData>((accumulator, key) => {
        if (data[key] instanceof Timestamp) {
          accumulator[key] = data[key].toDate();
        } else if (data[key] instanceof Array) {
          accumulator[key] = data[key].map((item: DocumentData) => convert(item));
        } else if (data[key] instanceof Object) {
          accumulator[key] = convert(data[key]);
        } else {
          accumulator[key] = data[key];
        }

        return accumulator;
      }, {});

    return convert(ss) as T;
  },
});

export default timestampConverter;
