import { Button, Container, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import Papa from 'papaparse';
import { useState } from 'react';
import PersonsService from '../../api/services/PersonsService';
import { Person } from '../../models/person';

export default function ImportPersonsPage() {
  const [csvContent, setCsvContent] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const saveData = async (data: Person) => {
    if (data.id) {
      try {
        await PersonsService.updatePersonById(data.id, data);
        enqueueSnackbar('Update Suceessfully!', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar('Update Error! Try Later', { variant: 'error' });
      }
    } else {
      try {
        await PersonsService.addNewPerson(data);
        enqueueSnackbar('Add Suceessfully!', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar('Save Error! Try Later', { variant: 'error' });
      }
    }
  };

  interface StringMap {
    [key: string]: string;
  }

  const onImportData = async () => {
    const data = Papa.parse<StringMap>(csvContent, {
      header: true,
    });

    data.data.forEach(async (p) => {
      const person: Person = {
        name: { first: p.First, last: p.Last, preferred: p.Alternative, nick: p.Nick },
        birthday: { year: Number(p.Year), month: Number(p.Month), day: Number(p.Day) },
        phones: undefined,
        addresses: undefined,
        notes: '',
        labels: [],
      };
      if (p['Phone1.Number'].length > 0 && person.phones) {
        person.phones.push({ number: p['Phone1.Number'], label: p['Phone1.Label'] });
      }
      if (p['Phone2.Number'].length > 0 && person.phones) {
        person.phones.push({ number: p['Phone2.Number'], label: p['Phone2.Label'] });
      }
      if (p['Address1.Text'].length > 0 && person.addresses) {
        person.addresses.push({ text: p['Address1.Text'], label: p['Address1.Label'] });
      }
      if (p['Address2.Text'].length > 0 && person.addresses) {
        person.addresses.push({ text: p['Address2.Text'], label: p['Address2.Label'] });
      }
      // console.log(person);
      await saveData(person);
    });

    console.log(data);
  };

  return (
    <Container>
      <TextField
        multiline
        variant="outlined"
        label="CSV Content"
        autoFocus
        minRows={20}
        maxRows={20}
        sx={{
          my: 2,
          width: '100%',
        }}
        onChange={(event) => {
          setCsvContent(event.target.value);
        }}
      />

      <Button variant="contained" onClick={onImportData}>
        Import
      </Button>
    </Container>
  );
}
