import { addDoc, doc, getDoc, getDocs, orderBy, query, updateDoc } from 'firebase/firestore';
import { Person } from '../../models/person';
import { personsCollection } from '../firestore';

const fetchAllPersons = async () => {
  const q = query(personsCollection, orderBy('name.last', 'desc'));
  const docsRef = await getDocs(q);
  const persons: Person[] = docsRef.docs.map((personRef) => {
    return { id: personRef.id, ...personRef.data() };
  });

  return persons;
};

const fetchPersonById = async (id: string) => {
  const personDocRef = doc(personsCollection, id);
  const personDoc = await getDoc(personDocRef);
  const person = personDoc.data();
  return person;
};

const updatePersonById = async (id: string, person: Person) => {
  const personDocRef = doc(personsCollection, id);
  await updateDoc(personDocRef, person);
};

const addNewPerson = async (person: Person) => {
  return addDoc(personsCollection, person);
};

export default {
  fetchAllPersons,
  fetchPersonById,
  updatePersonById,
  addNewPerson,
};
